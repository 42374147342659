var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "product-view", attrs: { flat: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mt-3 mb-2 mx-0" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0 pl-1 pt-1", attrs: { cols: "12" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  outlined: "",
                  dense: "",
                  label: _vm.$t("message.projects.project"),
                  items: _vm.listOfProjects,
                  "hide-details": "",
                  "item-text": "projectName",
                  "item-value": "id",
                },
                on: {
                  change: function ($event) {
                    return _vm.getProjectAndMaterils()
                  },
                },
                model: {
                  value: _vm.projectId,
                  callback: function ($$v) {
                    _vm.projectId = _vm._n($$v)
                  },
                  expression: "projectId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 70,
                  width: 7,
                  color: "primary",
                  indeterminate: "",
                },
              }),
            ],
            1
          )
        : _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _c(
                "v-card-text",
                { staticClass: "pt-0 pb-1" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0 pt-0", attrs: { cols: "6" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("message.projects.project")) +
                              ": " +
                              _vm._s(_vm.orderModel.number)
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0 pt-0 text-right",
                          attrs: { cols: "6" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("message.order.delivery")) +
                              ": " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.orderModel.deliverydate
                                )
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0 pl-3", attrs: { cols: "12" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("message.projects.customer")) +
                              ": " +
                              _vm._s(_vm.orderModel.customer_name || "")
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0 pl-3", attrs: { cols: "12" } },
                        [
                          _vm._v(
                            _vm._s(_vm.orderModel.customeraddress_address || "")
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0 pl-3", attrs: { cols: "12" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.orderModel.customeraddress_address2 || ""
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0 pl-3", attrs: { cols: "12" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.orderModel.customeraddress_postcode || ""
                            ) +
                              " " +
                              _vm._s(_vm.orderModel.customeraddress_city || "")
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.listOfProducts && _vm.listOfProducts.length > 0
                ? [
                    _c(
                      "v-card-text",
                      { staticClass: "highlight pt-0 pb-0 pl-1" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0 pl-3 font-weight-bold",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("message.order.products")) + ":"
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0 pl-3 font-weight-bold",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("message.deviation.description")
                                  ) + ":"
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "pa-0 pl-3 text-right font-weight-bold",
                                attrs: { cols: "3" },
                              },
                              [_vm._v(_vm._s(_vm.$t("message.order.avail")))]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "pa-0 pl-3 text-right font-weight-bold",
                                attrs: { cols: "3" },
                              },
                              [_vm._v(_vm._s(_vm.$t("message.order.ordered")))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0", attrs: { cols: "12" } },
                              [_c("v-divider", { staticClass: "pa-0" })],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._l(_vm.listOfProducts, function (product, index) {
                          return _c(
                            "v-row",
                            { key: index },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 pl-3",
                                  class: {
                                    "pb-1":
                                      index === _vm.listOfProducts.length - 1,
                                  },
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pa-0 pl-3 text-truncate",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              product.isfreight
                                                ? "1000"
                                                : product.number
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 text-truncate",
                                          attrs: {
                                            cols: "6",
                                            title: product.producttext,
                                          },
                                        },
                                        [_vm._v(_vm._s(product.producttext))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 pl-3 text-right",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v(_vm._s(product.product_available))]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 pl-3 text-right",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _vm._v(" " + _vm._s(product.qty) + " "),
                                  _c("span", { staticClass: "caption" }, [
                                    _vm._v(_vm._s(product.unit_name)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              disabled: !_vm.isProjectActive,
                            },
                            on: { click: _vm.pickOrderHandler },
                          },
                          [_vm._v(_vm._s(_vm.$t("message.order.pickProducts")))]
                        ),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.freightObj.dialog,
            callback: function ($$v) {
              _vm.$set(_vm.freightObj, "dialog", $$v)
            },
            expression: "freightObj.dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " + _vm._s(_vm.$t("message.common.deleteConfirm")) + " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "pa-5" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("message.order.addFreight")) + " "
                  ),
                  _c(
                    "v-form",
                    { ref: "freightForm" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  rules: _vm.$_norwegianNumberValidation,
                                  outlined: "",
                                  label: _vm.$t("message.order.cost"),
                                },
                                model: {
                                  value: _vm.cost,
                                  callback: function ($$v) {
                                    _vm.cost = $$v
                                  },
                                  expression: "cost",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  rules: _vm.$_norwegianNumberValidation,
                                  outlined: "",
                                  label: _vm.$t("message.order.price"),
                                },
                                model: {
                                  value: _vm.price,
                                  callback: function ($$v) {
                                    _vm.price = $$v
                                  },
                                  expression: "price",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        id: "freightConfirm",
                        loading: _vm.freightObj.loading,
                      },
                      on: { click: _vm.freightObj.onSuccess },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", id: "freightClose" },
                      on: { click: _vm.freightObj.onClose },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }